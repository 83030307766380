<template>
  <div id="external-preview" :style="{ width: formWidth }">
    <!-- header -->

    <!-- <div class="logo">
      <img :src="logo" alt="logo" :height="logoHeight" />
    </div> -->

    <!-- ... -->

    <!-- content -->
    <div id="content-wrapper">
      <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />
      <div class="content">
        <template>
          <div class="header col row">
            <div class="col">
              <div class="title-1">{{ title }}</div>
              <div class="description">{{ description }}</div>
            </div>
            <div
              class="col-auto text-secondary q-pa-sm cursor-pointer"
              @click="viewDetail"
            >
              View Detailed Information
              <BaseIcon
                name="eva-arrowhead-right"
                size="18px"
                color="secondary"
                class="icon"
              />
            </div>
          </div>
          <div class="row request-data">
            <div class="col-4 divider-right">
              <BaseScrollbar height="calc(100vh - 300px)">
                <q-stepper ref="stepper" vertical color="primary" animated>
                  <q-step
                    v-for="(level, index) in stageLevel"
                    :key="level.id"
                    :name="index"
                    :title="level.label"
                    :prefix="index + 1"
                    :done="currentStep >= index + 1"
                    done-color="green"
                  >
                  </q-step>
                </q-stepper>
              </BaseScrollbar>
            </div>
            <div class="col">
              <BaseScrollbar height="calc(100vh - 300px)">
                <template v-if="requestFormData.panels">
                  <table class="mainData col-12">
                    <template v-for="panel in requestFormData.panels">
                      <template v-if="panel.panelsExpandView">
                        <template v-for="(value, key) in panel.fields">
                          <template v-if="value && value != '[]'">
                            <template v-if="validateType(key) !== 'TABLE'">
                              <tr :key="key">
                                <td class="tblHeader ellipsis small">
                                  {{ getLabel(key) }}
                                </td>
                                <td class="ellipsis">
                                  <template v-if="validateType(key) === 'DATE'">
                                    {{ $day.format(value, false) }}
                                  </template>
                                  <template v-else-if="validateValue(value)">
                                    {{ removeTags(value) || "-" }}
                                  </template>
                                  <template v-else>
                                    <a :href="value" target="_blank">
                                      {{ value || "-" }}
                                    </a>
                                  </template>
                                </td>
                              </tr>
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </table>
                </template>
              </BaseScrollbar>
            </div>
            <div
              v-if="processActions.length && selectedProcess.review === ''"
              class="col-auto q-pa-md"
              style="margin: auto"
            >
              <template v-for="action in processActions">
                <BaseButton
                  v-if="
                    action === 'Approve' ||
                    action === 'Complete' ||
                    action === 'Rightsize' ||
                    action.indexOf('Approve') > -1
                  "
                  :key="action"
                  :label="action"
                  color="positive"
                  class="q-mb-md"
                  style="width: 100%"
                  @click="saveAction = action"
                />
                <BaseButton
                  v-else-if="
                    action === 'Reject' ||
                    action === 'Terminate' ||
                    action === 'Force Close' ||
                    action === 'Decline' ||
                    action.indexOf('Reject') > -1
                  "
                  :key="action"
                  :label="action"
                  color="red"
                  class="q-mb-md"
                  style="width: 100%"
                  @click="saveAction = action"
                />
                <BaseButton
                  v-else-if="
                    action === 'Forward' ||
                    action.indexOf('Reply') > -1 ||
                    action === 'Rollback' ||
                    action === 'Assign'
                  "
                  :key="action"
                  :label="action"
                  color="orange"
                  class="q-mb-md"
                  style="width: 100%"
                  @click="saveAction = action"
                />
                <BaseButton
                  v-else-if="action === 'To Requester'"
                  :key="action"
                  :label="action"
                  color="pink"
                  class="q-mb-md"
                  style="width: 100%"
                  @click="saveAction = action"
                />
                <BaseButton
                  v-else
                  :key="action"
                  :label="action"
                  class="q-mb-md"
                  style="width: 100%"
                  @click="saveAction = action"
                />
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- ... -->

    <!-- footer -->

    <div class="footer">EZOFIS &copy; 2024 All Rights Reserved</div>

    <!-- ... -->

    <Modal
      v-model="successModal"
      width="50vw"
      height="70vh"
      has-footer
      @input="closeSuccessModal"
    >
      <template #title>"{{ title }}" Request Submission</template>

      <template #default>
        <div class="success-modal">
          <Stepper
            :stage-level="stageLevel"
            :current-step="currentStep + 1"
            done-color="green"
            class="q-mb-md"
          />
          <div
            class="q-py-md text-md q-pa-sm text-center"
            style="background: #dbf6db; border-radius: 6px"
          >
            <span class="text-bold">Thank You for Your Submission!</span>
            <!-- <span>
            We’re processing your request and have moved it to the next step.
            Please find the current processing status below:
          </span> -->
          </div>
        </div>
      </template>

      <template #footer>
        <BaseButton label="Close" color="primary" @click="closeSuccessModal" />
      </template>
    </Modal>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="50vh"
      has-footer
      @input="
        {
          saveAction = '';
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title
        >"{{ selectedProcess.requestNo }}"
        <template> Process Forward </template>
      </template>

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <!-- user -->

          <MultiSelectField
            v-if="internalForward.proceed"
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="
              internalForward.users.length ? internalForward.users : userList
            "
            class="field"
          />

          <!-- ... -->

          <!-- group -->

          <MultiSelectField
            v-if="internalForward.groups.length"
            v-model="forwardGroups"
            label="group"
            :options="
              internalForward.groups.length ? internalForward.groups : groupList
            "
            class="field"
          />

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Forward"
          color="orange"
          @click="
            {
              processForwardModal = false;
              requestAction('Forward', false, false);
            }
          "
        />
      </template>
    </Modal>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = '';
          actionPasswordConfirm = false;
        }
      "
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          actionConfirmAlert = false;
        }
      "
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "<b>{{
              saveAction
            }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              processRequest(false, false);
            }
          "
        />
      </template>
    </Confirm>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { auth, report, workflow, form, user, group } from "@/api/factory.js";
import Stepper from "@/components/common/workflow/Stepper.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import Modal from "@/components/common/popup/Modal.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

export default {
  name: "ExternalPreview",

  components: {
    ValidationObserver,
    ValidationProvider,
    Stepper,
    Confirm,
    Modal,
    TextBuilderField,
    PasswordField,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    userId: {
      type: String,
      required: true,
    },

    workflowId: {
      type: String,
      required: true,
    },

    processId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingBarContent: true,
      formWidth: "100%",
      title: "",
      description: "",
      selectedProcess: {},
      workflow: {},
      formId: 0,
      form: null,
      formModel: {},
      processActions: [],
      processActionsAddOn: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
      actionText: "",
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      actionComments: false,
      actionConfirm: false,
      actionConfirmAlert: false,
      isLoading: false,
      workflowFormModel: {},
      saveAction: "",
      formFields: [],
      requestFormData: {},
      processForwardModal: false,
      internalForward: {
        proceed: false,
        users: [],
        groups: [],
      },
      userList: [],
      groupList: [],
      forwardUsers: [],
      forwardGroups: [],
      stageLevel: [],
      currentStep: 0,
      successModal: false,
    };
  },

  computed: {
    logo() {
      return require(`@/assets/logo/logo-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },

    logoHeight() {
      return "24px";
    },
  },

  watch: {
    userId: {
      immediate: true,
      handler: "getSession",
    },

    saveAction: {
      immediate: true,
      handler() {
        if (this.saveAction) {
          this.workflowFormModel = this.selectedProcess.formData.fields;
          this.processRequest();
        }
      },
    },
  },

  methods: {
    async getSession() {
      const { error } = await auth.externalLogin(this.tenantId, this.userId);
      if (error) {
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getProcess();
    },

    async getProcess() {
      const { error, payload } = await report.getWorkflowData(this.workflowId, {
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.processId,
              },
              {
                criteria: "actionUser",
                condition: "IS_EQUALS_TO",
                value: this.userId,
              },
            ],
          },
        ],
        itemsPerPage: 10,
        currentPage: 1,
        mode: "",
      });
      if (error) {
        this.loadingBarContent = false;
        this.$alert.error(error);
        return;
      }
      if (payload.meta.totalItems) {
        if (payload.data.length) {
          if (payload.data[0].value.length) {
            this.selectedProcess = payload.data[0].value[0];
            this.title = this.selectedProcess.requestNo;
            this.getWorkflow();
          }
        }
      }
    },

    async getWorkflow() {
      const { error, payload } = await workflow.getWorkflow(this.workflowId);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);
      if (workflowJson.settings.general.initiateUsing.formId) {
        this.getForm(workflowJson.settings.general.initiateUsing.formId);
      }
      this.description = `${this.workflow.name} - ${this.selectedProcess.stage}`;
      this.formEditAccess = true;
      if (
        this.selectedProcess.activityUserEmail ===
        this.$store.state.session.email
      ) {
        let block = workflowJson.blocks.find(
          (block) => block.id === this.selectedProcess.activityId
        );
        if (block) {
          if (
            this.selectedProcess.lastAction === "Forward" &&
            block.settings.forwardedUserAction === "REPLY"
          ) {
            this.processActions = [];
            this.processActionsAddOn = [];
            this.processActions.push(
              "Reply to " + this.selectedProcess.transaction_createdByEmail
            );
            this.processActionsAddOn.push({
              action:
                "Reply to " + this.selectedProcess.transaction_createdByEmail,
              remarks: true,
              passwordAccess: false,
              confirm: false,
            });
            this.customReply = true;
          } else {
            if (
              block.settings.userReply &&
              this.processActions.indexOf("Reply") === -1
            ) {
              this.processActions.push(
                "Reply to " + this.selectedProcess.lastActionStageName
              );
              this.userReply = true;
            }
            if (
              block.settings.toInitiator &&
              this.processActions.indexOf("To Requester") === -1
            ) {
              this.processActions.push("To Requester");
              this.toRequester = true;
            }
            if (block.settings.internalForward) {
              this.processActions.push("Forward");
              this.internalForward.proceed = true;
              this.internalForward.users = [];
              this.internalForward.groups = [];
              if (block.settings.internalForwardUser) {
                if (block.settings.internalForwardUser.length) {
                  block.settings.internalForwardUser.forEach((user) => {
                    let userFilter = this.userList.filter(
                      (row) => row.value == user
                    );
                    if (userFilter.length) {
                      this.internalForward.users.push({
                        id: this.$nano.id(),
                        label: userFilter[0].label,
                        value: user,
                      });
                    }
                  });
                }
              } else {
                this.getUsers();
              }
              if (block.settings.internalForwardGroup) {
                if (block.settings.internalForwardGroup.length) {
                  block.settings.internalForwardGroup.forEach((group) => {
                    let groupFilter = this.groupList.filter(
                      (row) => row.value == group
                    );
                    if (groupFilter.length) {
                      this.internalForward.groups.push({
                        id: this.$nano.id(),
                        label: groupFilter[0].label,
                        value: group,
                      });
                    }
                  });
                }
              } else {
                this.getGroups();
              }
            }
          }
          workflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === this.selectedProcess.activityId) {
              if (this.processActions.indexOf(rule.proceedAction) < 0) {
                this.processActions.push(rule.proceedAction);
                this.processActionsAddOn.push({
                  action: rule.proceedAction,
                  remarks: rule.remarks || false,
                  passwordAccess: rule.passwordAccess || false,
                  confirm: rule.confirm || false,
                });
              }
            }
          });
          if (block.settings.formEditAccess) {
            if (block.settings.formEditAccess === "") {
              this.formEditAccess = true;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "FULL") {
              this.formEditAccess = false;
              this.formEditControls = [];
            } else if (block.settings.formEditAccess === "PARTIAL") {
              this.formEditAccess = false;
              this.formEditControls = block.settings.formEditControls;
            }
          }
          if (block.settings.formVisibilityAccess) {
            this.formVisibilityAccess = true;
            this.formSecureControls = [];
          } else {
            this.formVisibilityAccess = false;
            this.formSecureControls = block.settings.formSecureControls;
          }
          if (
            workflowJson.settings.general.processNumberPrefix.indexOf("[") > -1
          ) {
            let requestNumberFormat = JSON.parse(
              workflowJson.settings.general.processNumberPrefix
            );
            this.readonlyControls = [];
            let requestColumn = requestNumberFormat.filter(
              (row) => row.key === "formColumn"
            );
            if (requestColumn.length) {
              requestColumn.forEach((row) => {
                this.readonlyControls.push(row.value);
                this.formEditControls.splice(
                  this.formEditControls.indexOf(row.value),
                  1
                );
              });
            }
          }
        }
      }
    },

    async getForm(fId) {
      const { error, payload } = await form.getForm(fId);
      if (error) {
        this.$alert.error(error);
        return;
      }
      this.form = JSON.parse(payload.formJson);
      this.formId = fId.toString();

      const panels = [...this.form.panels, ...this.form.secondaryPanels];

      if (!panels.length) {
        return;
      }
      let fields = [];
      for (const panel of panels) {
        fields.push(...panel.fields);
      }

      fields.forEach((field) => {
        if (
          field.type !== "DIVIDER" &&
          field.type !== "LABEL" &&
          field.type !== "PARAGRAPH" &&
          (field.label || field.type === "TABLE") &&
          field.settings.general.visibility !== "DISABLE"
        ) {
          this.formFields.push({
            id: this.$nano.id(),
            label: field.label || field.type,
            value: field.id,
            type: field.type,
            settings: field.settings,
          });
        }
      });
      this.requestFormData = await this.transformFormDataToPanelsArray(
        this.selectedProcess.formData,
        this.form.panels
      );
      this.getProcessHistory();
    },

    transformFormDataToPanelsArray(formData, panels) {
      const transformedFormData = {
        formId: formData.formId,
        formEntryId: formData.formEntryId,
        panels: [],
      };

      panels.forEach((panel, index) => {
        const panelTitle = panel.settings.title;
        const panelFields = panel.fields;

        const panelData = {
          name: panelTitle,
          panelsExpandView: index === 0,
          fields: {},
        };

        panelFields.forEach((field) => {
          const fieldId = field.id;
          const fieldValue = formData.fields[fieldId] || "";
          panelData.fields[fieldId] = fieldValue;
        });

        transformedFormData.panels.push(panelData);
      });

      return transformedFormData;
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      if (payload) {
        payload.map((user) => {
          if (user.id !== this.$store.state.session.id)
            this.userList.push({
              id: this.$nano.id(),
              label: user.value || user.loginName,
              value: user.id,
            });
        });
      }
    },

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    async getProcessHistory() {
      const { error, payload } = await workflow.processHistory(
        this.workflowId,
        this.processId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.stageLevel = [];
        this.currentStep = 0;
        let activityId = "";
        payload.forEach((row) => {
          if (row.actionStatus !== 2 && activityId !== row.activityId) {
            activityId = row.activityId;
            this.stageLevel.push({
              id: row.activityId,
              label: row.stage,
              status: row.status,
            });
            if (
              ((row.actionUser || row.subWorkflowHistory) && row.status) ||
              (row.actionUser === null && !row.subWorkflowHistory)
            ) {
              this.currentStep += 1;
            }
          }
          // if (row.actionStatus !== 2) {
          //   if (
          //     this.stageLevel.filter((stage) => stage.id === row.activityId)
          //       .length === 0
          //   ) {
          //     this.stageLevel.push({
          //       id: row.activityId,
          //       label: row.stage,
          //       status: row.status,
          //       processedBy: row.processedBy,
          //       processedOn: this.$day.format(row.processedOn),
          //     });
          //     if (
          //       ((row.actionUser || row.subWorkflowHistory) &&
          //         row.status &&
          //         row.status !== "Forward") ||
          //       (row.actionUser === null && !row.subWorkflowHistory)
          //     ) {
          //       this.currentStep += 1;
          //     }
          //   } else if (row.status) {
          //     if (
          //       this.stageLevel.filter(
          //         (stage) =>
          //           stage.id === row.activityId && stage.status !== row.status
          //       ).length
          //     ) {
          //       this.currentStep += 1;
          //     } else {
          //       this.currentStep -= 1;
          //     }
          //   } else {
          //     let index = this.stageLevel.findIndex(
          //       (stage) => stage.id === row.activityId
          //     );
          //     if (index > -1) {
          //       this.stageLevel.splice(index, this.stageLevel.length - index);
          //       this.stageLevel.push({
          //         id: row.activityId,
          //         label: row.stage,
          //         status: row.status,
          //         processedBy: row.processedBy,
          //         processedOn: this.$day.format(row.processedOn),
          //       });
          //       if (
          //         (row.actionUser && row.status && row.status !== "Forward") ||
          //         row.actionUser === null
          //       ) {
          //         this.currentStep = index + 1;
          //       } else {
          //         this.currentStep = index;
          //       }
          //     }
          //   }
          // }
        });
      }
      this.loadingBarContent = false;
    },

    validateType(key) {
      let fieldType = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        let formField = panel.fields.find((field) => field.id === key);
        if (formField) {
          fieldType = formField.type;
        }
      });
      return fieldType;
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "table") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id && field.settings.general.hideLabel === false) {
              controlLabel = field.label;
              return;
            }
          }
        }
      });
      return controlLabel;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    closeSuccessModal() {
      location.reload();
    },

    viewDetail() {
      location.href =
        location.origin + location.pathname.replace("/preview", "");
    },

    async processRequest(forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.saveAction === "Forward" && this.internalForward.proceed) {
          this.processForwardGroupAction = false;
          this.processForwardModal = true;
          return;
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === this.saveAction
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionComments = true;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }
      let inputData = {};
      if (this.formId) {
        inputData = {
          workflowId: this.workflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: this.saveAction === "Save" ? "" : this.saveAction,
          formData: {
            formId: this.formId,
            formEntryId: this.selectedProcess.formData.formEntryId,
            fields: this.workflowFormModel,
          },
        };
      }
      this.saveAction = "";
      this.workflowFormModel = {};
      this.loadingBarContent = true;
      const { error } = await workflow.processTransaction(inputData);
      this.loadingBarContent = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else {
        this.processActions = [];
        this.successModal = true;
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.selectedProcess.processId) {
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
            }
          );

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
        if (this.actionComments) {
          this.processRequest(false, false);
        }
      }
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.actionComments = true;
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.processRequest(false, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#external-preview {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: auto;
  padding: 16px;
  background: linear-gradient(135deg, #dec2e7 0%, #b2ebf2 100%);

  .logo {
    text-align: center;
    height: 46px;
    align-items: center;
    padding: 0px 16px;
  }

  #content-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-content {
      width: 80vw !important;
      height: 70vh !important;
    }

    .content {
      width: 80vw;
      background-color: var(--component-bg-color);
      border-radius: 6px;
      padding: 18px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12);

      .header {
        padding: 16px;
        border-radius: 6px;
        // background-color: #eee;
        margin-bottom: 16px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12);

        .description {
          @extend .text-sm;
          color: $primary;
        }
      }

      .request-data {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
          0 3px 1px -2px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        background: #fff;

        .divider-right {
          border-right: 3px solid var(--divider-color);
        }

        table {
          &.submittedData {
            background: $primary-1;
          }
          &.mainData {
            table-layout: fixed;
          }
          width: 100%;

          tr {
            height: 36px;

            th {
              color: $primary-11;
              text-align: left;
            }
          }

          th,
          td {
            padding: 8px;
            font-weight: 500;
          }

          .tblHeader {
            color: $primary-11;
            font-weight: 500;
            &.small {
              width: 200px;
            }
          }
        }
      }

      .q-stepper {
        box-shadow: none !important;
        border-radius: none !important;
      }
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}
</style>
<style lang="scss">
#external-preview,
#modal .success-modal {
  .q-stepper--vertical .q-stepper__tab.q-stepper__tab--done,
  .q-stepper--horizontal .q-stepper__tab.q-stepper__tab--done {
    padding: 12px 24px 24px 24px !important;

    .q-stepper__title {
      color: $primary;
    }
  }

  .q-stepper__dot span {
    color: green;
    background: white;
  }

  .q-stepper--vertical .q-stepper__dot,
  .q-stepper--horizontal .q-stepper__dot {
    background: white;
    border: 2px solid;
  }
}
</style>
