import { render, staticRenderFns } from "./Preview.vue?vue&type=template&id=684cf5ae&scoped=true&"
import script from "./Preview.vue?vue&type=script&lang=js&"
export * from "./Preview.vue?vue&type=script&lang=js&"
import style0 from "./Preview.vue?vue&type=style&index=0&id=684cf5ae&prod&lang=scss&scoped=true&"
import style1 from "./Preview.vue?vue&type=style&index=1&id=684cf5ae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "684cf5ae",
  null
  
)

export default component.exports
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QStepper,QStep});
